import React from "react"

import Layout from "../components/layout"
import { SEO } from "../components/seo"

const Page = () => {
  return (
    <Layout>
      <SEO
        title={`Laseroterapia`}
        description={`Laseroterapia - zabiegi odmładzania skóry oraz usuwania blizn, przebarwień i rozstępów.`}
      />

      <div className="offer-item">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-xs-12">
              <h1 className="offer-item-title">Laseroterapia</h1>
              <div className="separator-line-horizontal"></div>
              <p className="offer-item-description">
                Lasery frakcyjne to genialne urządzenia, dzięki którym
                przeprowadza się zabiegi odmładzania skóry oraz usuwania blizn,
                przebarwień i rozstępów. Naświetlaniu można poddać twarz, szyję,
                dekolt, brzuch, plecy i dłonie. Efekty są długotrwałe i widoczne
                już po jednej wizycie w klinice medycyny estetycznej. W naszej
                klinice posiadamy Laser Frakcyjny CO2 oraz Laser frakcyjny
                nieablacyjny.
              </p>
              <p className="offer-item-description">
                Laser frakcyjny, nieablacyjny, jest urządzeniem wyposażonym w
                diodę laserową. Długość światła laserowego w urządzeniu to
                1470nm, a maksymalna moc wyjściowa 2W, urządzenie przeznaczone
                jest do nieablacyjnego resurfacingu skóry. Laser działa na
                zasadzie "dziurkowania" skóry za pomocą skupionej wiązki
                laserowej, co w konsekwencji prowadzi do regeneracji i
                przebudowy skóry. Punkty są równomiernie rozmieszczone z
                możliwością dostosowania odległości miedzy nimi od 0,8 do 4mm.
                Energia dostarczana do skóry w jednym punkcie może być
                regulowana od 10mJ do 50mJ. Głowica lasera pozwala pracować na
                dużych obszarach zabiegowych, ale również umożliwia dostosowanie
                wypełnienia okna zabiegowego do okolic mniejszych i trudno
                dostępnych. Zaprojektowany specjalnie do zabiegów, które nie
                wymagają czasu rekonwalescencji.
              </p>
              <p className="offer-item-description">
                Lasery frakcyjne, nieablacyjne działają na skórę z
                niedoskonałościami poprzez skupienie światła na głębszych
                warstwach skóry, aby rozbić, uszkodzić tkankę bliznowatą bez
                czasu rekonwalescencji w odróżnieniu do procedur bardziej
                agresywnych np. laserów ablacyjnych. Uszkodzenie termiczne,
                skierowane na tkankę i główny chromofor- wodę, która wydala
                ciepło, jest źródłem i najprawdopodobniej indukuje odpowiedź
                zapalną z podwyższoną regulacją cytokin i proliferacją
                fibroblastów. Późniejsze odkładanie się kolagenu w skórze
                właściwej w konfiguracji równoległej odpowiada za poprawę
                kliniczną odnotowaną kilka miesięcy po zakończeniu leczenia
                laserami frakcyjnymi, ablacyjnymi.
              </p>
              <p className="offer-item-description">
                Zabiegi laserem nieablacyjnym można wykonywać praktycznie cały
                rok! Nie wymagają znieczulenia, są bezbolesne, nie wymagają
                pozabiegowej rekonwalescenci!
              </p>
            </div>

            <div className="col-md-12 col-xs-12">
              <h2 className="offer-item-title">
                Czym jest laser frakcyjny CO2?
              </h2>
              <div className="separator-line-horizontal"></div>
              <p className="offer-item-description">
                Laser frakcyjny CO2 to jedno z najbardziej znanych ,
                niezawodnych technicznie urządzeń stosowanych w medycynie
                estetycznej i dermatologii. Został stworzony do przeprowadzania
                zabiegów regenerujących skórę, wygładzających zmarszczki,
                a także likwidujących  lekkie przebarwienia, blizny i rozstępy.
                Służy także do usuwania zmian typu włókniaki, brodawki (w tym
                HPV).
              </p>
              <p className="offer-item-description">
                Działanie lasera frakcyjnego CO2 polega na rozbiciu wiązki
                promienia laserowego na mniejsze, które są rozprowadzane
                równomiernie po skórze na głębokość 2–3 mm. W wyniku
                naświetlania powstają mikrouszkodzenia, które stymulują komórki
                naskórka i skóry właściwej do szybkiej regeneracji. Naświetlaniu
                można poddać twarz (łącznie z okolicą oczu), szyję,
                dekolt, dłonie, brzuch i plecy.
              </p>
              <p className="offer-item-description">
                Zabiegi można wykonywać od września do kwietnia jednak skórę
                należy przygotowywać już latem, stosując na noc preparaty
                z retinolem lub kwasami.
              </p>
            </div>

            <div className="col-md-12 col-xs-12">
              <h2 className="offer-item-title">
                Dla kogo przeznaczone są zabiegi laserem frakcyjnym CO2 i
                laserem frakcyjnym nieablacyjnym?
              </h2>
              <div className="separator-line-horizontal"></div>
              <p className="offer-item-description">
                Zabiegi medycyny estetycznej laserem frakcyjnym są przeznaczone
                dla osób, które chcą poprawić wygląd skóry – odmłodzić, wzmocnić
                elastyczność, zregenerować, a także pobudzić produkcję kolagenu
                i przyspieszyć proces gojenia się ran. Naświetlanie jest
                przeznaczone zarówno dla kobiet, jak i mężczyzn. Wskazania
                do zabiegu:
              </p>
              <div className="offer-item-description">
                <ul>
                  <li>zmarszczki na twarzy, wokół oczu, szyi i dekolcie</li>
                  <li>zwiotczenie skóry</li>
                  <li>blizny różnego pochodzenia (np. potrądzikowe)</li>
                  <li>przebarwienia i zmiany pigmentowe na skórze</li>
                  <li>rozstępy</li>
                  <li>nierówności na skórze</li>
                  <li>rozszerzone pory</li>
                </ul>
              </div>
            </div>

            <div className="col-md-12 col-xs-12">
              <h2 className="offer-item-title">
                Przebieg zabiegu laserem frakcyjnym
              </h2>
              <div className="separator-line-horizontal"></div>
              <p className="offer-item-description">
                Podczas pierwszej wizyty lekarz medycyny estetycznej
                przeprowadza rozmowę z pacjentem i udziela mu wszystkich
                niezbędnych informacji dotyczących przebiegu zabiegu
                oraz zaleceń przed i po. Omawiane są również oczekiwania
                dotyczące efektów. Należy odstawić kosmetyki z retinolem i wit C
                na dwa tygodnie przed zabiegiem !! Przed zabiegiem laserem
                frakcyjnym CO2 skóra jest dokładnie oczyszczana
                i zdezynfekowana. Lekarz ustawia odpowiednie parametry
                urządzenia i przystępuje do naświetlania. Mikroskopijne wiązki
                światła przebijają 25% powierzchni skóry na głębokość 2–3
                milimetrów. W ten sposób tworzy się siatka mikrouszkodzeń, które
                pobudzają procesy naprawcze i przebudowę kolagenu przez zdrowe
                tkanki. Naświetlanie laserem frakcyjnym CO2 może powodować
                dyskomfort i nieprzyjemne uczucie mrowienia, dlatego skórę można
                znieczulić maścią, a w trakcie zabiegu chłodzić ją specjalnym
                aparatem. Dzięki temu dolegliwości są zredukowane do minimum.
                Zabieg laserem nieablacyjnym jest całkowicie bezbolesny, nie
                wymaga znieczulenia.
              </p>
            </div>

            <div className="col-md-12 col-xs-12">
              <h2 className="offer-item-title">
                Efekty jakie można osiągnąć dzięki laserom?
              </h2>
              <div className="separator-line-horizontal"></div>
              <p className="offer-item-description">
                Efekty po zabiegu laserem frakcyjnym są widoczne stopniowo
                po około 4 tygodniach od wizyty w klinice medycyny estetycznej.
                Zmiany można zaobserwować już po jednym naświetlaniu, jednak,
                aby osiągnąć lepsze rezultaty należy wykonać 2–4 zabiegi. Zabieg
                laserem frakcyjnym pozwala osiągnąć następujące efekty:
              </p>
              <div className="offer-item-description">
                <ul>
                  <li>poprawa wyglądu cery</li>
                  <li>wygładzenie zmarszczek i młodsza skóra</li>
                  <li>wygładzenie skóry</li>
                  <li>usunięcie blizn, rozstępów i przebarwień</li>
                  <li>ujędrnienie skóry</li>
                  <li>stymulacja produkcji i przebudowa kolagenu</li>
                </ul>
              </div>
            </div>

            <div className="col-md-12 col-xs-12">
              <h2 className="offer-item-title">Zalecenia po zabiegu laserem</h2>
              <div className="separator-line-horizontal"></div>
              <p className="offer-item-description">
                PO zabiegu laserem frakcyjnym nieablacyjnym może wystąpić lekkie
                zaczerwienie i złuszczanie jednak pacjent nie wymaga
                rekonwalescencji i już następnego dnia może wracać do pracy!
              </p>
              <p className="offer-item-description">
                Inaczej wygląda sprawa po bardziej agresywnym leczeniu laserem
                CO2. Przez kilka dni po zabiegu laserem CO2 skóra może być
                zaczerwieniona, obrzęknięta, napięta i przesuszona. U niektórych
                pacjentów występuje lekkie pieczenie lub świąd. Dodatkowo
                w procesie gojenia zwykle występuje zbrązowienie i złuszczanie
                się naskórka. Rekonwalescencja trwa około 7 dni (w zależności
                od zastosowanego preparatu). W tym czasie należy ściśle stosować
                się do zaleceń lekarza, aby nie dopuścić np. do zabrudzenia ran
                i infekcji.
              </p>
              <p className="offer-item-description">
                Zalecenia po zabiegu laserem CO2 to przede wszystkim odpowiednia
                pielęgnacja skóry. Wskazane jest stosowanie kremów
                nawilżających. Warto pamiętać, aby produkt na dzień miał ochronę
                przeciwsłoneczną (SPF minimum 30). Nie można wykonywać peelingów
                ani innych czynności przyspieszających złuszczanie się naskórka.
                Zaleca się unikanie gorących kąpieli, basenu, sauny i alkoholu
                podczas procesu gojenia. Kobiety mogą nałożyć mineralny makijaż
                już 48 godzin po naświetlaniu. Dlaczego rekonwalescencja jest
                ważna? Dzięki niej skóra goi się właściwie, nie dochodzi
                do zakażenia, ani powikłań. Wygląd jest świeży i naturalny,
                a efekty zabiegu utrzymują się dłużej. Rekonwalescencja
                to tylko 7 dni rezygnacji ze swoich codziennych nawyków, ale jej
                wpływ utrzymuje się nawet przez kilka lat. Dobrze pielęgnowana
                cera po zabiegu odwdzięczy się pięknym wyglądem.
              </p>
            </div>

            <div className="col-md-12 col-xs-12">
              <h2 className="offer-item-title">
                Przeciwwskazania do zabiegu laserem
              </h2>
              <div className="separator-line-horizontal"></div>
              <p className="offer-item-description">
                Zabieg z wykorzystaniem lasera nie może być przeprowadzony
                u wszystkich pacjentów. Przeciwwskazania to:
              </p>
              <div className="offer-item-description">
                <ul>
                  <li>skłonności do powstawania blizn przerostowych</li>
                  <li>infekcje skóry</li>
                  <li>
                    opalanie się na słońcu lub na solarium w ciągu ostatnich 2–3
                    tygodni
                  </li>
                  <li>
                    przyjmowanie niektórych leków na 6 miesięcy przed zabiegiem
                    (retinoidów, immunosupresyjnych, środków zmniejszających
                    krzepliwość krwi)
                  </li>
                  <li>ciąża i okres karmienia piersią</li>
                  <li>stosowanie wypełniaczy w miejscu wykonania zabiegu</li>
                  <li>
                    ciężkie choroby (nowotwór, niewydolność nerek, cukrzyca)
                  </li>
                </ul>
              </div>
              <p className="offer-item-description">
                Takim osobom proponowane są inne metody i zabiegi medycyny
                estetycznej, które poprawią wygląd, zregenerują skórę i pozwolą
                uzyskać zamierzone efekty.
              </p>
            </div>

            <div className="col-md-12 col-xs-12">
              <div className="separator-line-horizontal"></div>
            </div>

            <div className="col-md-12 col-xs-12">
              <h2 className="offer-item-title">Laser tulowy</h2>
              <div className="separator-line-horizontal"></div>
              <p className="offer-item-description">
                to innowacyjny laser o długości fali 1927 nm ze skanerem
                frakcyjnym umożliwiający wykonywanie skutecznych i bezpiecznych
                zabiegów z zachowaniem minimalnego okresu rekonwalescencji w
                szerokiej grupie wskazań.
              </p>
              <p className="offer-item-description">
                Ze względu na charakterystykę długości fali, moc lasera oraz
                możliwość regulacji parametrów, laser ten umożliwia pracę w 3
                trybach: nieablacyjnym, subablacyjnym oraz ablacyjnym. Laser
                tulowy sprawdza się doskonale w zabiegach odmładzania,
                przebudowy skóry, jak również w redukcji zmian pigmentacyjnych
                czy w zabiegach na stymulację wzrostu włosów. Subablacyjny tryb
                specyficzny dla tego lasera jest optymalnym rozwiązaniem
                potwierdzonym przez wielu specjalistów do leczenia zmian
                pigmentacji podstawnej warstwy naskórka, w której występują duże
                ilości melanocytów i melaniny.
              </p>
              <p className="offer-item-description">
                Długość fali 1927 nm lasera tulowego charakteryzuje się wysokim
                powinowactwem do wody, w związku z czym oddziałuje na naskórek i
                górną warstwę skóry właściwej. Zabiegi z wykorzystaniem lasera
                tulowego wiążą się z wysokim profilem bezpieczeństwa dla
                pacjenta, co wynika z mechanizmu jego oddziaływania. W
                przeciwieństwie do innych systemów, laser wyróżnia się wysoką
                intensywnością z jednoczesną delikatnością. Jako jedyny laser
                umożliwia dynamiczną regulację mocy, długość czasu trwania i
                ilość impulsów, co skutkuje płynną zmianą trybu pracy pomiędzy
                nieablacyjnym, ablacyjnym i subablacyjnym.
              </p>
              <p className="offer-item-description">
                Działanie lasera tulowego umożliwia także stosowanie mezoterapii
                laserowej z wykorzystaniem kosmeceutyków. Większość składników
                aktywnych w nich zawartych ulega rozkładowi jeszcze przed
                wniknięciem w skórę. Lavieen oddziałuje przede wszystkim na
                powierzchnię pomiędzy naskórkiem i skórą właściwą, tworząc
                mikrokanaliki polepszające absorpcję składników aktywnych.
                Interfejs jest przyjazny i łatwy w obsłudze. System umożliwia
                pełną kontrolę nad parametrami pracy, jak również dostosowanie
                rozmiaru (nawet 30 mm średnicy plamki zabiegowej) i kształtu
                pola zabiegowego.
              </p>
            </div>

            <div className="col-md-12 col-xs-12">
              <h2 className="offer-item-title">Sposób działania lasera</h2>
              <div className="separator-line-horizontal"></div>
              <p className="offer-item-description">
                Jedyny taki laser łączący zalety innych. Możliwość pracy w 3
                trybach.
              </p>
              <p className="offer-item-subtitle">NIEABLACYJNY</p>
              <div className="offer-item-description">
                <ul>
                  <li>Efekt termiczny w skórze właściwej</li>
                  <li>Zapoczątkowanie procesu denaturacji białek</li>
                  <li>Zabiegi odmładzające</li>
                  <li>Brak okresu rekonwalescencji</li>
                </ul>
              </div>
              <p className="offer-item-subtitle">SUBABLACYJNY</p>
              <div className="offer-item-description">
                <ul>
                  <li>
                    Struktura rogowej warstwy naskórka ulega minimalnemu
                    uszkodzeniu
                  </li>
                  <li>Koagulacja i denaturacja</li>
                  <li>Leczenie melasmy, piegów, hiperpigmentacji</li>
                  <li>Krótka rekonwalescencja</li>
                </ul>
              </div>
              <p className="offer-item-subtitle">ABLACYJNY</p>
              <div className="offer-item-description">
                <ul>
                  <li>Ablacja rogowej warstwy naskórka</li>
                  <li>Denaturacja białek</li>
                  <li>Blizny, rozstępy</li>
                  <li>głęboka przebudowa skóry</li>
                  <li>Dłuższy okres rekonwalescencji</li>
                </ul>
              </div>
            </div>

            <div className="col-md-12 col-xs-12">
              <h2 className="offer-item-title">Wskazania</h2>
              <div className="separator-line-horizontal"></div>
              <p className="offer-item-description">
                Poza usuwaniem przebarwień laser tulowy ma także inne wskazania,
                do których należą:
              </p>
              <div className="offer-item-description">
                <ul>
                  <li>blizny potrądzikowe</li>
                  <li>drobne zmarszczki</li>
                  <li>duże pory</li>
                  <li>melasma naskórka</li>
                  <li>napięcie skóry</li>
                  <li>nieprawidłowa tekstura skóry</li>
                  <li>piegi</li>
                  <li>plamy starcze – efekt odmłodzenia</li>
                  <li>przebarwienia pozapalne</li>
                  <li>tonowanie skóry (wybielanie)</li>
                  <li>stymulacja wzrostu włosów</li>
                  <li>leczenie rozstępów</li>
                </ul>
              </div>
            </div>

            <div className="col-md-12 col-xs-12">
              <h2 className="offer-item-title">
                Przeciwwskazania do zabiegu Laserowego
              </h2>
              <div className="separator-line-horizontal"></div>
              <div className="offer-item-description">
                <ul>
                  <li>Opalenizna (1miesiąc), SAMOOPALACZE!!!</li>
                  <li>Leki światło uczulające (retinoidy, tetracykliny)</li>
                  <li>
                    Zioła światłouczulające ( dziurawiec, nagietek, skrzyp)
                  </li>
                  <li>Żelazo (przestać brać min 3 tyg przed zabiegem)</li>
                  <li>
                    Zioła ( dziurawiec, nagietek, przestać używać na 2-3
                    tygodnie przed zabiegiem)
                  </li>
                  <li>Aktywne choroby skóry (np. łuszczyca, liszaj)</li>
                  <li>Czynna infekcja skóry (np. opryszczka)</li>
                  <li>Peelingi (na 4 tyg. przed zabiegiem)</li>
                  <li>
                    Depilacja woskiem lub pęsetą na 2 tyg. przed zabiegiem - w
                    okolicy poddawanej zabiegowi
                  </li>
                  <li>Bardzo sucha i bardzo wrażliwa skóra</li>
                  <li>Bielactwo</li>
                  <li>
                    Skłonność do powstawania bliznowców (keloidów), blizn
                    hipertroficznych
                  </li>
                  <li>Stosowanie sterydów (krem lub tabletki)</li>
                  <li>
                    Stosowanie retinoidów doustnych w ciągu ostatnich 6 miesięcy
                  </li>
                  <li>
                    Spożycie lub okres 6 miesięcy od zakończenia przyjmowania
                    Accutanu
                  </li>
                  <li>
                    Zaburzenia krzepnięcia krwi, stosowanie leków
                    zmniejszających krzepliwość krwi
                  </li>
                  <li>Padaczka</li>

                  <li>Choroba nowotworowa</li>
                  <li>Rozrusznik serca</li>
                  <li>
                    Choroby hormonalne oraz endokrynologiczne takie jak np:
                    zespół policystycznego jajnika lub cukrzyca
                  </li>
                  <li>Zakażenia wirusowe: HIV, WZW</li>
                  <li>
                    Czynne choroby autoimmunologiczne (np. toczeń układowy)
                  </li>
                  <li>Ciąża</li>
                  <li>Karmienie piersią</li>
                  <li>Spożywanie alkoholu (24 godziny przed zabiegiem)</li>
                  <li>Nadmierne oczekiwania wobec efektów zabiegu</li>
                </ul>
              </div>
            </div>

            <div className="col-md-12 col-xs-12">
              <h2 className="offer-item-title">
                Zalecenia pozabiegowe po laserze tulowym:
              </h2>
              <div className="separator-line-horizontal"></div>
              <div className="offer-item-description">
                <ul>
                  <li>
                    Po zabiegu może wystąpić zaczerwienienie, suchość skóry,
                    swędzenie oraz niewielki ból przez 2-3 dni
                  </li>
                  <li>
                    Zalecane jest użycie kremu nawilżającego w związku z
                    możliwym uczuciem suchości skóry
                  </li>
                  <li>
                    Promieniowanie UV może być przyczyną występowania
                    przebarwień, w związku z tym należy stosować krew z filtrem
                    UV co 3-4 godziny
                  </li>
                  <li>
                    Należy unikać sauny oraz wysiłku fizycznego w ciągu 2
                    tygodni od dnia przeprowadzenia zabiegu. Zalecane jest
                    utrzymywanie skóry w chłodnej temperaturze np. poprzez
                    przemywanie zimną wodą.
                  </li>
                  <li>
                    Krem z filtrem przeciwsłonecznym należy nakładać na skórę
                    zarówno w pomieszczeniu jak i na zewnątrz. Zalecany czas
                    ochrony przeciwsłonecznej to minimum 2 miesiące.
                  </li>
                  <li>
                    Pomocne w rekonwalescencji jest picie dużej ilości wody
                  </li>
                  <li>
                    Po zabiegu laserem pacjent może skorzystać z innych zabiegów
                    mających na celu złagodzić i zregenerować skórę
                  </li>
                  <li>
                    Niewielkie strupy pojawiają się w ciągu 3-5 dni od zabiegu,
                    co jest naturalne dla zabiegu laserowego. Może także
                    wystąpić zaciemnienie skóry . Strupy samoistnie odpadną w
                    wyniku naturalnych procesów regeneracyjnych
                  </li>
                </ul>
              </div>
              <p className="offer-item-description">
                W razie jakichkolwiek niepokojących objawów należy pilnie
                skontaktować się z Balicka Clinic
              </p>
            </div>

            <div className="col-md-12 col-xs-12">
              <div className="separator-line-horizontal"></div>
            </div>

            <div className="col-md-12 col-xs-12">
              <h2 className="offer-item-title">Laser naczyniowy</h2>
              <div className="separator-line-horizontal"></div>
              <p className="offer-item-description">
                Lasery emitujące promieniowanie żółte, które jest dobrze
                absorbowane przez melaninę i hemoglobinę, są innowacją
                umożliwiającą usuwanie zmian naczyniowych i pigmentacyjnych
                efektywnie, bezpiecznie, szybko i delikatnie, ze zredukowanymi
                efektami ubocznymi.
              </p>
            </div>

            <div className="col-md-12 col-xs-12">
              <h2 className="offer-item-title">Zastosowanie</h2>
              <div className="separator-line-horizontal"></div>
              <p className="offer-item-description">
                Laser ten to pierwszy na świecie żółty laser dermatologiczny ze
                skanerem frakcyjnym. Dzięki zastosowaniu specjalnej długości
                fali 577nm laser na naczynka stanowi złoty standard w zabiegach
                naczyniowych, takich jak:
              </p>
              <div className="offer-item-description">
                <ul>
                  <li>naczynka</li>
                  <li>znamiona pajączkowate</li>
                  <li>rumień</li>
                  <li>trądzik różowaty</li>
                  <li>czerwone pajączki żylne</li>
                  <li>zmiany typu „plamy rozlanego wina”</li>
                  <li>naczyniaki</li>
                </ul>
              </div>
            </div>

            <div className="col-md-12 col-xs-12">
              <h2 className="offer-item-title">Działanie</h2>
              <div className="separator-line-horizontal"></div>
              <p className="offer-item-description">
                Światło lasera penetrując skórę reaguje precyzyjnie wyłącznie ze
                zmianami naczyniowymi, a nie z otaczającą skórą. Hemoglobina
                zawarta we krwi pochłania energię światła, co powoduje trwałe
                zamknięcie czerwonych naczynek krwionośnych. Selektywne
                działanie dotyczące tylko naczynek warunkuje duże bezpieczeństwo
                zabiegu – skóra nie jest uszkadzana, więc po zabiegu nie
                pozostają żadne ślady oprócz zaczerwienienia, które mija po paru
                dniach. Niezawodność, skuteczność i bezpieczeństwo naszego
                lasera zostało potwierdzone w licznych badaniach klinicznych.
                Jego ograniczeniem jest jedynie brak działania na naczynka
                niebieskie (szersze żyłki widoczne najczęściej na nogach)
              </p>
              <p className="offer-item-description">
                Drugim zastosowaniem lasera naczyniowego emitującego światło
                żółte jest usuwanie ograniczonych, drobnych przebarwień na
                twarzy i rękach. Zarówno w przypadku usuwania naczynek jak i
                przebarwień zabieg przebiega podobnie.
              </p>
            </div>

            <div className="col-md-12 col-xs-12">
              <h2 className="offer-item-title">Opis zabiegu</h2>
              <div className="separator-line-horizontal"></div>
              <p className="offer-item-description">
                Podczas gdy laser przesuwa się po skórze, pacjent odczuwa liczne
                małe ukłucia na powierzchni skóry. Zwykle nie stanowią one
                dużego dyskomfortu. W zależności od wielkości obszaru leczonego
                zabieg trwa od 10 do ok. 20 minut. Efekt zaniknięcia naczynek
                pacjent może zaobserwować bezpośrednio po zabiegu. Większość
                pacjentów uzyskuje ustąpienie lub znaczną poprawę zmian
                naczyniowych już po 1 lub 2 sesjach laseroterapii, niekiedy
                potrzebne są 3 – 4 zabiegi. Czy i kiedy należy powtórzyć
                leczenie zależy od indywidualnych uwarunkowań.
              </p>
            </div>

            <div className="col-md-12 col-xs-12">
              <h2 className="offer-item-title">Przeciwwskazania</h2>
              <div className="separator-line-horizontal"></div>
              <div className="offer-item-description">
                <ul>
                  <li>stany zapalne skóry (opryszczka)</li>
                  <li>plama soczewicowata złośliwa</li>
                  <li>cukrzyca</li>
                  <li>
                    zaburzenia krzepnięcia i przyjmowanie leków
                    przeciwzakrzepowych (aspiryna)
                  </li>
                  <li>ciąża, karmienie piersią</li>
                  <li>zmiany nowotworowe</li>
                  <li>bielactwo</li>
                  <li>łuszczyca</li>
                  <li>wrażliwość na światło</li>
                  <li>świeża opalenizna</li>
                  <li>
                    używanie preparatów światłouczulających (z retinolem,
                    witaminą C, kwasami AHA, BHA, z dziurawcem, nagietkiem,
                    tetracykliny, retinoidy)
                  </li>
                  <li>epilepsja</li>
                  <li>skłonność do bliznowacenia</li>
                </ul>
              </div>
            </div>

            <div className="col-md-12 col-xs-12">
              <h2 className="offer-item-title">
                Postępowanie po zabiegu laserem naczyniowym:
              </h2>
              <div className="separator-line-horizontal"></div>
              <div className="offer-item-description">
                <ul>
                  <li>
                    Po zabiegu może wystąpić zaczerwienienie, suchość skóry,
                    obrzęk , swędzenie oraz niewielki ból przez 2-3 dni
                  </li>
                  <li>
                    Zalecane jest użycie kremu nawilżającego w związku z
                    możliwym uczuciem suchości skóry ( krem poleci operator w
                    czasie zabiegu np. Cicalfat )
                  </li>
                  <li>
                    Promieniowanie UV może być przyczyną występowania
                    przebarwień, w związku z tym należy stosować krew z filtrem
                    UV co 3-4 godziny
                  </li>
                  <li>
                    Należy unikać sauny oraz wysiłku fizycznego w ciągu 2
                    tygodni od dnia przeprowadzenia zabiegu. Zalecane jest
                    utrzymywanie skóry w chłodnej temperaturze np. poprzez
                    przemywanie zimną wodą.
                  </li>
                  <li>
                    Krem z filtrem przeciwsłonecznym należy nakładać na skórę
                    zarówno w pomieszczeniu jak i na zewnątrz. Zalecany czas
                    ochrony przeciwsłonecznej to minimum 2 miesiące.
                  </li>
                  <li>
                    Pomocne w rekonwalescencji jest picie dużej ilości wody
                  </li>
                  <li>
                    Po zabiegu laserem pacjent może skorzystać z innych zabiegów
                    mających na celu złagodzić i zregenerować skórę
                  </li>
                  <li>
                    Niewielkie strupy pojawiają się w ciągu 3-5 dni od zabiegu,
                    co jest naturalne dla zabiegu laserowego. Strupy samoistnie
                    odpadną w wyniku naturalnych procesów regeneracyjnych. W
                    razie zabrudzenia należy przemyć skórę Octaniseptem, a
                    następnie ponownie nałożyć krem regenerujący np. Cicalfat
                  </li>
                </ul>
              </div>
              <p className="offer-item-description">
                W przypadku wystąpienia jakichkolwiek niepokojących objawów
                należy niezwłocznie skontaktować się z operatorem który
                wykonywał zabieg.
              </p>
            </div>

            <div className="col-md-12 col-xs-12">
              <div className="separator-line-horizontal"></div>
            </div>

            <div className="col-md-12 col-xs-12">
              <h2 className="offer-item-title">Laser pikosekundowy</h2>
              <div className="separator-line-horizontal"></div>
              <p className="offer-item-description">
                Laser pikosekundowy to rozwiązanie, które nie ma sobie równych
                na rynku. To urządzenie, które umożliwia przeprowadzenie
                zabiegów z zakresu laseroterapii. Laser znalazł swoje
                zastosowanie np. w usuwaniu nieudanego makijażu
                permanentnego oraz tatuażu. W trakcie zabiegu maszyna
                wykorzystuje najkrótszą wiązkę laserową liczoną w pikosekundach
                (a więc znacznie krócej, niż nanosekunda). Dla Pacjenta oznacza
                to krótkotrwały kontakt światła ze skórą, a także krótszy czas
                zabiegu, większe bezpieczeństwo i skuteczność. Pikosekundowy
                laser posiada źródło światła o podwójnej długości fali. Wiązka
                światła w kontrolowany i precyzyjny sposób dociera nawet w
                głębokie warstwy skóry, pobudzając jej naturalne procesy
                naprawcze. Dzięki temu laser pikosekundowy doskonale sprawdza
                się także w redukcji przebarwień czy rozstępów. Zabieg laserem
                pikosekundowym jest praktycznie bezbolesny. Ból to co prawda
                odczucie indywidualne każdego człowieka, dlatego niektórzy
                Pacjenci mogą czuć nieco większy dyskomfort, niż inni, jednak to
                urządzenie nie ma sobie równych w tej kwestii. Poza tym sam
                proces rekonwalescencji nie wymaga tak dużo czasu, jak w
                przypadku tradycyjnego urządzenia. Wiązka lasera ma znacznie
                krótszy kontakt ze skórą, a więc redukuje też ryzyko wystąpienia
                jej przegrzania czy poparzeń. Porównując działanie lasera
                pikosekundowego, żadne z wcześniej dostępnych rozwiązań nie
                rozbijało pigmentu barwnika na tak drobny pył. Dzięki temu
                pigment jest jeszcze szybciej eliminowany z organizmu,
                a usuwanie tatuażu czy makijażu permanentnego następuje przy
                zmniejszonej ilości zabiegów. Impulsy laserowe osiągają
                ukierunkowany prosto na pigment efekt fotoakustyczny i
                pozostawiają otaczające tkanki nietknięte.
              </p>
            </div>

            <div className="col-md-12 col-xs-12">
              <h2 className="offer-item-title">
                Na czym polega działanie lasera pikosekundowego?
              </h2>
              <div className="separator-line-horizontal"></div>
              <p className="offer-item-description">
                Laser pikosekundowy wiąże się z bardzo krótkim czasem trwania
                impulsu. To z kolei łączy się z dużą energią w trakcie
                stosowania urządzenia. Laser działa, rozbijając pigment
                wprowadzony w skórę. Do tego celu stosuje tzw. efekt
                fotoakustyczny. Długość fali, która jest absorbowana, zależy od
                koloru pigmentu w skórze. Po tym czasie jest on rozrywany, a
                powstałe na skutek tego procesu niewielkie fragmenty są wydalane
                z organizmu. Żeby całkowicie usunąć makijaż permanentny lub
                tatuaż za pomocą lasera pikosekundowego, niezbędne jest
                przeprowadzenie od 3 do 10 serii.
              </p>
            </div>

            <div className="col-md-12 col-xs-12">
              <h2 className="offer-item-title">
                Laser pikosekundowy – jakie ma zalety?
              </h2>
              <div className="separator-line-horizontal"></div>
              <p className="offer-item-description">
                Laser pikosekundowy jest obecnie najmocniejszym tego typu
                urządzeniem wśród dostępnego sprzętu medycznego. Sprawdza się
                nie tylko do usuwania makijażu permanentnego czy tatuażu, ale
                też różnych przebarwień, piegów, plam, znamion czy leczenia
                blizn potrądzikowych. Uniwersalne zastosowanie lasera jest
                jednak jedną z nielicznych zalet, jakie oferuje. To nowoczesne
                urządzenie korzysta ze światła, które bez przeszkód rozprawia
                się z pigmentem. Dzięki temu laser gwarantuje znacznie lepsze
                rezultaty niż inne, tradycyjne techniki usuwania makijażu
                permanentnego.
              </p>
              <p className="offer-item-description">
                Dodatkowo minimalizuje ryzyko pojawienia się infekcji czy
                powstawania blizn, a równocześnie zmniejsza dyskomfort w trakcie
                zabiegu.
              </p>
            </div>

            <div className="col-md-12 col-xs-12">
              <h2 className="offer-item-title">Wskazania do zabiegu</h2>
              <div className="separator-line-horizontal"></div>
              <p className="offer-item-description">
                Wskazaniami do wykonania zabiegu za pomocą lasera
                pikosekundowego są m.in.:
              </p>
              <div className="offer-item-description">
                <ul>
                  <li>Usunięcie tatuaży (również kolorowych)</li>
                  <li>
                    Usunięcie przebarwień (piegów, słonecznych, hormonalnych,
                    pozapalnych, stanowiących efekt fotostarzenia się skóry)
                  </li>
                  <li>Rogowacenia dosłoneczne</li>
                  <li>Odmładzanie skóry</li>
                  <li>Pozapalne zaczerwienienia</li>
                  <li>Trądzik zapalny, różowaty i rumień</li>
                  <li>Usunięcie makijażu permanentnego</li>
                  <li>Leczenie blizn potrądzikowych i powypadkowych</li>
                  <li>Usuwanie rozstępów</li>
                  <li>Melasma</li>
                  <li>Nieablacyjna rewitalizacja skóry</li>
                  <li>Usuwanie mikropigmentacji skóry głowy</li>
                </ul>
              </div>
            </div>

            <div className="col-md-12 col-xs-12">
              <h2 className="offer-item-title">Jakie są przeciwwskazania?</h2>
              <div className="separator-line-horizontal"></div>
              <p className="offer-item-description">
                Mimo że efekty zapewniane przez laser pikosekundowy w redukcji
                nieudanego makijażu permanentnego lub tatuażu są imponujące, to
                nie zawsze można z nich skorzystać. Przeciwwskazaniem do zabiegu
                jest m.in.: nowotwór skóry czy świeża opalenizna. Zaleca się
                zrezygnować z eksponowania ciała na słońce minimum przez 14 dni
                przed zabiegiem. Lekarz odradzi terapię także w przypadku
                Pacjentów ze skłonnością do powstawania bliznowców, aktywnymi
                infekcjami skórnymi. Przeciwwskazania to też przyjmowanie leków
                oraz ziół o właściwościach fotouczulających – zmniejszających
                naturalne zdolności ochronne skóry przed działaniem szkodliwych
                promieni UV. Jest to m.in. dziurawiec, ruta, aminek zwyczajny,
                rumianek, arnika czy nagietek. Przed zabiegiem lekarz
                przeprowadzi dokładny wywiad z Pacjentem, aby wykluczyć ryzyko
                ewentualnych przeciwwskazań.
              </p>
            </div>

            <div className="col-md-12 col-xs-12">
              <h2 className="offer-item-title">Jakie są efekty zabiegu?</h2>
              <div className="separator-line-horizontal"></div>
              <p className="offer-item-description">
                Rezultaty lasera pikosekundowego to głównie redukcja tatuaży,
                blizn czy przebarwień. Skóra zyskuje wyraźne wygładzenie,
                jędrność i elastyczność, a wszystkie niedoskonałości w formie
                zmarszczek czy rozstępów, ulegają spłyceniu. W przypadku
                usuwania tatuaży, ich likwidacja zależy od rodzaju pigmentu.
                Zwykle zaleca się wykonanie serii zabiegów od 3 do 10. Rezultaty
                można jednak zauważyć już po jednej wizycie. Przy łagodnych
                zmianach barwnikowych wystarczy zwykle jeden zabieg, aby uzyskać
                trwały efekt. Usunięcie przebarwień hormonalnych jest z kolei
                możliwe już po 3 zabiegach.
              </p>
            </div>

            <div className="col-md-12 col-xs-12">
              <h2 className="offer-item-title">Zalecenia pozabiegowe</h2>
              <div className="separator-line-horizontal"></div>
              <p className="offer-item-description">
                Po zabiegu laserem pikosekundowym zaleca się całkowitą
                rezygnację z eksponowania skóry na działanie promieniowania UV.
                Nie należy też stosować filtrów przeciwsłonecznych. Pacjent
                powinien z kolei pamiętać o miejscowym nawilżeniu i schładzaniu
                skóry, a także o piciu dużej ilości wody. Nie można uszkadzać
                powstałych strupków.
              </p>
            </div>

            <div className="col-md-12 col-xs-12">
              <h2 className="offer-item-title">
                Laser pikosekundowy – jakie daje możliwości?
              </h2>
              <div className="separator-line-horizontal"></div>
              <p className="offer-item-description">
                Stosując pikosekundową wiązkę laserową, można spodziewać się
                szybszych efektów usuwania tatuażu i mniejszej liczby
                potrzebnych zabiegów. Ma to ogromne znaczenie szczególnie przy
                usuwaniu niechcianych tatuaży, których barwnik wprowadzony jest
                zbyt głęboko. Klasyczny laser wymaga w tym celu większej liczby
                zabiegów, ponieważ posiada dłuższy impuls i mniejszą energię.
                Liczba zabiegów potrzebna do całkowitego usunięcia tatuażu
                zależy jednak od wielu czynników, np.:
              </p>
              <div className="offer-item-description">
                <ul>
                  <li>Głębokości, na jakiej znajduje się barwnik</li>
                  <li>Rodzaju i koloru barwnika</li>
                  <li>Wieku tatuażu</li>
                  <li>Powierzchni tatuażu</li>
                </ul>
              </div>
              <p className="offer-item-description">
                Usuwanie tatuażu laserem pikosekundowym wykonywane jest zwykle
                przy zastosowaniu znieczulenia powierzchniowego kremem. To z
                kolei pozwala zapewnić Pacjentowi pełen komfort. Zabieg
                doskonale sprawdza się też przy usuwaniu makijażu permanentnego.
                Precyzyjnie dobierając parametry urządzenia, można zarówno
                całkowicie go zredukować, jak i pozbyć się jego części w celu
                korekty kreski.
              </p>
              <p className="offer-item-description">
                W przypadku redukcji zmian barwnikowych procedura wygląda tak
                samo, jak przy usuwaniu tatuaży. Efekty uzyskuje się jednak
                nieco szybciej, ponieważ w przebarwieniach jest mniej barwnika.
                Niektóre zmiany, np. plamy starcze wymagają często tylko jednego
                zabiegu. Leczenie na niewielkich powierzchniach nie wymaga
                znieczulenia.
              </p>
              <p className="offer-item-description">
                Laserem pikosekundowym można też leczyć blizny zanikowe.
                Działanie urządzenia wywołuje zjawisko LIOB, w którego trakcie
                dochodzi do kawitacji. Konsekwencją takiej sytuacji jest
                pobudzenie skóry do procesów naprawczych i jej przebudowy. Skóra
                produkuje nowy kolagen i włókna elastyczne. Widoczna poprawa
                następuje już po jednym zabiegu, ale finalny efekt uzyskuje się
                po kilku zabiegach w odstępie 2 – 4 tygodnie.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Page
